import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUserMinus, FaRegEdit } from "react-icons/fa";
import { BiUserPlus } from "react-icons/bi";
import { Url } from "../../Global";
import { Backdrop, Box, CircularProgress, Container, Divider, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import ReactPaginate from "react-paginate";
import { Breadcrumb } from "react-bootstrap";
import { Paper, TablePagination } from '@mui/material';



const ClosedList = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const LoadEdit = (id) => {

    navigate("/leadedit/" + id);

  }

  const [leaddta, leaddtachange] = useState([]);
  useEffect(() => {
    setLoading(true);
    fetch(Url + "closed_complaints")
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        leaddtachange(resp.data);
        console.log(resp);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (

      <Container maxWidth>
        <Breadcrumb>
          <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item active>Closed Complaint List</Breadcrumb.Item>
        </Breadcrumb>
        <Divider />
        {loading &&
          <LinearProgress />}
        <Box maxWidth>         

          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"><b>Sl.No</b></TableCell>
                    <TableCell align="left"><b>Ticket</b></TableCell>
                    <TableCell align="left"><b>Name</b></TableCell>
                    <TableCell align="left"><b>Address</b></TableCell>
                    <TableCell align="left"><b>Category</b></TableCell>
                    <TableCell align="left"><b>Complaint</b></TableCell>
                    <TableCell align="left"><b>Staff Closed</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaddta.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell align="left">{item.ticket}</TableCell>
                      <TableCell align="left">{item.customer}</TableCell>
                      <TableCell align="left">{item.address}</TableCell>
                      <TableCell align="left">{item.category}</TableCell>
                      <TableCell align="left">{item.complaint}</TableCell>
                      <TableCell align="left">{item.closedStaffName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={leaddta.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        </Container>

     
      );
};
      export default ClosedList;
