import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { AdminUrl, Url } from '../../Global';

const ReassignModal = ({ show, handleClose, complaintId , staffId }) => {

    const [staffdtls, staffdtlschange] = useState(null);
    const [assignedStaffId, setassignedStaffId] = useState(null);


    const handleSubmit = () => {
          
        const custdta = {
            assignedStaffId, complaintId , staffId
          };   
        fetch(AdminUrl + "complaint_reassign", {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(custdta)
        })
            .then((res) => {
                handleClose();

            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    useEffect(() => {
        fetch(Url + "staff")
            .then((res) => {
                return res.json();
            })
            .then((resp) => {
                staffdtlschange(resp.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);


    return (
        <Modal size='md' show={show} onHide={handleClose} style={{ paddingTop: 150 }}>
             
            <Modal.Header closeButton>
                <Modal.Title>Change / Re-Assign Staff</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           
               

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  

                    <Form.Label column sm="4">
                        Staff
                    </Form.Label>
                    <Col sm="8">
                        <Form.Select aria-label="Default select example" onChange={(e) => setassignedStaffId(e.target.value)}>
                        <option>Select Staff</option>
                          { staffdtls &&
                          staffdtls.map((staffs) => {
                            return(
                                    <option  key={staffs.id} value={staffs.id}>{staffs.name}</option>
                          )})}
                        </Form.Select>
                    </Col>

                </Form.Group>

               


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSubmit}>
                    Submit
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            
        </Modal>
    );
};

export default ReassignModal;