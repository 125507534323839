var userDetails = [];

const Reducer=(state=userDetails,action) => {
    if(action.type=="adduser"){
        userDetails.push(action.value);
    }

    console.log(state);
return state;
}
export default Reducer;