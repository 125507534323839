import React, { useEffect, useState } from 'react'
import { Url } from '../../Global';

const DashCard = () => {

    const [complaintdata ,complaintdatachange] = useState();
    const [leaddata , leaddatachange] = useState();
    const [totalcust , totalcustchange] = useState();

    useEffect(() => {
      fetch(Url + "dashboard_datas")
        .then((res) => {
          return res.json();
        })
        .then((resp) => {
          leaddatachange(resp.data.leadTot);  
          complaintdatachange(resp.data.complintsTot);
          totalcustchange(resp.data.customerTot)   
          
        })
        .catch((err) => {
          console.log(err.message);
        });
    }, []);

  return (
    <div>
       <section class="section dashboard">
     

       
        <div class="col-lg-12">
          <div class="row">

          
            <div class="col-md-3">
              <div class="card info-card sales-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div class="card-body">
                  <h5 class="card-title">Total Customers <span>| Cable</span></h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-people-fill"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{totalcust}</h6>
                     

                    </div>
                  </div>
                </div>

              </div>
            </div>
           

            <div class="col-md-3">
              <div class="card info-card sales-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div class="card-body">
                  <h5 class="card-title">Total Customers <span>| Broadband</span></h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-rss-fill"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{totalcust/2-900}</h6>
                     

                    </div>
                  </div>
                </div>

              </div>
            </div>

            
            <div class="col-md-3">
              <div class="card info-card sales-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div class="card-body">
                  <h5 class="card-title">Total Complaints <span>| Today</span></h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-sunset-fill"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{complaintdata}</h6>
                      

                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div class="col-md-3">
              <div class="card info-card revenue-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div class="card-body">
                  <h5 class="card-title">Total Leads <span>| This Month</span></h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-check2-all"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{leaddata}</h6>
                      

                    </div>
                  </div>
                </div>

              </div>
            </div>

            
          
    </div>
    </div>
  
    </section>
    </div>
  )
}

export default DashCard
