import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { Url } from '../../Global';

const LeadModal = ({ show, handleClose }) => {

    const [staffdtls, staffdtlschange] = useState(null);
    const [name, namechange] = useState("");
    const [address, addresschange] = useState("");
    const [mobile, mobilechange] = useState("");
    const [leadThrough, leadThroughchange] = useState("");
    const [staffId, staffIdchange] = useState("");
    const [type, typechange] = useState();
    const [leadCategory, leadCategorychnge] = useState("");


    const handleSubmit = (e) => {
        e.preventDefault();
        const datas = { name, address, mobile, leadThrough, staffId, type, leadCategory };
        fetch(Url + "lead", {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(datas),
        })
            .then((res) => {
                handleClose();

            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    useEffect(() => {
        fetch(Url + "staff")
            .then((res) => {
                return res.json();
            })
            .then((resp) => {
                staffdtlschange(resp.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

console.log(staffId)
    return (
        <Modal show={show} onHide={handleClose} style={{ paddingTop: 25 }}>
             <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Create Lead</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="2">
                        Name
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" required onChange={(e) => namechange(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="2">
                        Phone
                    </Form.Label>
                    <Col sm="5">
                        <Form.Control type="text" required onChange={(e) => mobilechange(e.target.value)} />
                    </Col>

                    <Form.Label column sm="2">
                        Type
                    </Form.Label>
                    <Col sm="3">
                        <select className='form-control' aria-label="Default select example" value={type} onChange={(e) => typechange(e.target.value)}>

                            <option value="Cable TV">Cable TV</option>
                            <option value="Broadband">Broadband</option>
                            <option value="Both">Both</option>
                        </select>
                    </Col>

                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="2">
                        Address
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" required onChange={(e) => addresschange(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3">
                        Location
                    </Form.Label>
                    <Col sm="4">
                        <Form.Control type="text" onChange={(e) => leadThroughchange(e.target.value)} required/>
                    </Col>

                    <Form.Label column sm="2">
                        Staff
                    </Form.Label>
                    <Col sm="3">
                        <Form.Select aria-label="Default select example" onChange={(e) => staffIdchange(e.target.value)}>
                          { staffdtls &&
                          staffdtls.map((staffs) => {
                            return(
                                    <option  key={staffs.id} value={staffs.id}>{staffs.name}</option>
                          )})}
                        </Form.Select>
                    </Col>

                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3">
                        Date
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control type="date" />
                    </Col>
                </Form.Group>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type='submit'>
                    Submit
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
};

export default LeadModal;