import { useEffect, useState } from "react";
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { FaUserMinus, FaRegEdit, FaWindowClose } from "react-icons/fa";

import LeadAddPop from "../Lead/LeadAddPop";
import Pops from "../Lead/Pops";

import {

  Box,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,

} from "@material-ui/core";
import { Url, AdminUrl } from "../../Global";
import { BiUserPlus } from "react-icons/bi";
import toast, { Toaster } from "react-hot-toast";
import { Button, Modal } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Popover from '@mui/material/Popover';




export default function MasterList() {

  const [rmvstatus, rmvstatuschange] = useState(null);
  const [newldstatus, newldstatuschange] = useState(0);
  const [status, statuschange] = useState();
  const [address, addresschange] = useState();
  const [authorisedPerson, authorisedPersonchange] = useState();
  const [email, emailchange] = useState();
  const [mobile, mobilechange] = useState();
  const [name, namechange] = useState();
  const [noOfStaff, noOfStaffchange] = useState();
  const [password, passwordchange] = useState();
  const [userId, userIdchange] = useState();
  const [companydta, companydtachange] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handlesubmit = (e) => {
    e.preventDefault();
    const leaddta = {
      address,
      authorisedPerson,
      email,
      mobile,
      name,
      noOfStaff,
      password,
      userId,
    };
    fetch(AdminUrl + "company", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(leaddta),
    })
      .then((res) => {
        toast.success("Company Added Successfully")
        handleClose();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };






  useEffect(() => {
    fetch( AdminUrl + "company")
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        companydtachange(resp.data);


      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [companydta]);




  const [openPopup, setOpenPopup] = useState(false);
  const [active, setactive] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (event) => {
    setactive(event.target.checked);
  };


  return (

   <>


       




        <Container maxWidth="lg">
          <div className="divbtn">
            <div><Toaster position="top-center"
              reverseOrder={false} /></div>
            <Button aria-describedby={id} className="btn btn-primary" variant="contained" onClick={handleShow}>
              NEW
            </Button>
            

            <br></br><br></br>
          </div>
          <h5 className="card-header">Master Admin</h5>
          <TableContainer >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"><b>SL.NO</b></TableCell>
                  <TableCell align="center"><b>COMPANY ID</b></TableCell>
                  <TableCell align="left"><b>NAME</b></TableCell>
                  <TableCell align="left"><b>USERNAME</b></TableCell>
                  <TableCell align="left"><b>WHATSAPP</b></TableCell>
                  <TableCell align="left"><b>MESSAGE</b></TableCell>
                  <TableCell align="left"><b>STATUS</b></TableCell>
                  <TableCell align="center"><b>ACTION</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companydta &&
                  companydta.map((row, index) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 5 } }}
                    >
                      <TableCell component="th" scope="row">
                        <center> {index + 1}</center>
                      </TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.userId}</TableCell>
                      <TableCell align="left"><Switch defaultChecked color="primary" /></TableCell>
                      <TableCell align="left"><Switch defaultChecked color="primary" /></TableCell>
                      <TableCell align="left"> <Chip
                        label={active ? 'Active ' : 'Inactive '}
                        color={active ? 'primary' : 'secondary'}
                        size="small"
                      /></TableCell>
                      <TableCell align="center">



                        <Switch checked={active}
              onChange={handleChange} color="primary"/> </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

      
      

      <Modal show={show} onHide={handleClose} size="lg" backdrop="true" animation="true" style={{paddingTop:'25px'}}>
        <Modal.Header closeButton>
          <Modal.Title>Company Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form class="row g-3"  validate required onSubmit={handlesubmit}>
                <div class="col-md-6">
                  <label for="inputName5" class="form-label">Company Name</label>
                  <input type="text" class="form-control" id="inputName5" required onChange={(e) => namechange(e.target.value)}/>
                </div>
                <div class="col-md-6">
                  <label for="inputName5" class="form-label">Authorized Person Name</label>
                  <input type="text" class="form-control" id="inputName5" required onChange={(e) => authorisedPersonchange(e.target.value)}/>
                </div>
                <div class="col-md-6">
                  <label for="inputEmail5" class="form-label">Email</label>
                  <input type="email" class="form-control" id="inputEmail5" required onChange={(e) => emailchange(e.target.value)}/>
                </div>
                <div class="col-md-6">
                  <label for="inputEmail5" class="form-label">Mobile</label>
                  <input type="number" class="form-control" id="inputEmail5"  required onChange={(e) => mobilechange(e.target.value)}/>
                </div>
                <div class="col-md-6">
                  <label for="inputPassword5" class="form-label">Username</label>
                  <input type="text" class="form-control" id="inputPassword5"  required onChange={(e) => userIdchange(e.target.value)}/>
                </div>
                <div class="col-md-6">
                  <label for="inputPassword5" class="form-label">Password</label>
                  <input type="password" class="form-control" id="inputPassword5" required onChange={(e) => passwordchange(e.target.value)}/>
                </div>
                <div class="col-12">
                  <label for="inputAddress5" class="form-label">Address</label>
                  <input type="text" class="form-control" id="inputAddres5s" placeholder="1234 Main St" required onChange={(e) => addresschange(e.target.value)}/>
                </div>               
                <div class="col-md-6">
                  <label for="inputCity" class="form-label">City</label>
                  <input type="text" class="form-control" id="inputCity"/>
                </div>
                <div class="col-md-4">
                  <label for="inputState" class="form-label">State</label>
                  <select id="inputState" class="form-select">
                    <option selected>Choose...</option>
                    <option>...</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label for="inputZip" class="form-label">Pin</label>
                  <input type="text" class="form-control" id="inputZip"/>
                </div>

                <div class="col-md-4">
                  <label for="inputZip" class="form-label">Number Of Staff</label>
                  <input type="text" class="form-control" id="inputZip"/>
                </div>
               
             
              </form></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

