const companyid = JSON.parse(localStorage.getItem('cmpid'));


const Url = 'https://api.cableman.app/TECHNIQUES/techniques/company/' + companyid + '/'
const AdminUrl = 'https://api.cableman.app/TECHNIQUES/techniques/'

const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    localStorage.removeItem("cmpid");
    window.location.href = "/";
  };

export { Url , AdminUrl , handleLogout }